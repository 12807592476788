<template>
  <div>
    <select v-model="selectedAccountId">
      <option :value="account.id" v-for="account in ad_accounts" :key="account.id">
        {{ account.name }}
      </option>
    </select>
    <div>Total: {{totalSites}}</div>
    <div v-if="selectedAccountId">
      <FormSubmitter  ref=            "form"
                      :path=          "'settings/taboola_block_site'"
                      :disabled=      "!input"
                      @onSuccess=     "added"
                      submitLabel=    "Add"
                      :feedbackObject="feedbackObject"
      >
        <label>
          Add to global blacklist:
          <b-form-input size="sm" name="site_id" v-model="input" type="text" class="w-auto"/>
          <input name="account_id" v-model="selectedAccountId" type="hidden"/>
        </label>
      </FormSubmitter>
    </div>
    <div v-if="selectedAccountId && !blacklistNetwork.request.isProcessing">
      <table class="text-left table table-bordered table-responsive-md">
        <thead>
        <tr>
          <th>Site ID</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="blockedSite in blacklistedSites" v-bind:key="blockedSite">
          <td>{{blockedSite}}</td>
          <td>
            <NetworkButton
              method="post"
              path="settings/taboola_unblock_site"
              :feedbackObject="feedbackObject"
              :payload="{site_id: blockedSite, account_id: selectedAccountId}"
              :disabled="false"
              @onSuccess="removed"
            >
              Remove
            </NetworkButton>
          </td>
        </tr>
        </tbody>
      </table>
      <b-pagination ref="pager"
                    pills
                    size="sm"
                    :limit="13"
                    align="center"
                    v-model="page"
                    :per-page="payload.page_size"
                    :disabled="blacklistNetwork.request.isProcessing"
                    last-number
                    first-number
                    :total-rows="pTotalSites"
      >
        <template v-slot:ellipsis-text>
          <span v-if="blacklistNetwork.request.isProcessing"><b-spinner small></b-spinner></span>
        </template>>
      </b-pagination>
    </div>
  </div>
</template>

<script>
  import genericRequest from '../../../components/generic/NetworkRequest.js'

  export default {
    mixins: [genericRequest],
    props: {
      feedbackObject: Object,
    },
    data() {
      return {
        page:               1,
        input:              null,
        payload:            { page_size: 50 },
        totalSites:         0,
        pTotalSites:        0,
        ad_accounts:        [],
        blacklistNetwork:   this.createNetworkObject(),
        blacklistedSites:   [],
        selectedAccountId:  null
      }
    },
    mounted() {
      this.getAdAccounts()
    },
    methods: {
      removed(payload) {
        this.feedbackObject.text = `${payload.site_id} was removed from the blacklist`;
        let index = this.blacklistedSites.indexOf(payload.site_id);
        this.blacklistedSites.splice(index, 1);
        this.totalSites --;
      },
      added(payload) {
        this.feedbackObject.text = `${payload.get('site_id')} was added to the blacklist`;
        this.blacklistedSites.unshift(payload.get('site_id'));
        this.totalSites ++;
      },
      getBlacklist() {
        this.request('post', 'settings/taboola_account_blacklist', this.blacklistNetwork, Object.assign({}, this.payload, {page: this.page - 1, account_id: this.selectedAccountId}))
          .then(res => {
            this.totalSites       = res.total;
            this.pTotalSites      = res.total;
            this.blacklistedSites = res.sites || []
          })
      },
      getAdAccounts() {
        this.request('get', 'ad_accounts?platform=taboola', this.blacklistNetwork)
          .then(res => {
            this.ad_accounts = res.ad_accounts
          })
      }
    },
    watch: {
      selectedAccountId: function() {
        this.getBlacklist();
      },
      page: function() {
        this.getBlacklist();
      },
      'blacklistNetwork.response.status': function(status) {
        this.feedbackObject.class = status;
        this.input = null
      },
      'blacklistNetwork.response.message': function(message) {
        this.feedbackObject.text = message
      },
      input: function() {
        this.$refs.form.validateChange()
      }
    }
  }
</script>
